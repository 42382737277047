import Layout from "../../components/layout"
import Heading from "../../components/heading"
import HorizontalImage from "../../components/horizontal-image"
import Text from "../../components/text"
import VerticalImage from "../../components/vertical-image"
import VerticalText from "../../components/vertical-text"
import React from 'react';
import { graphql } from "gatsby"

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "ledarny-branik/branik-1.jpg" }) { ...fluidImage }
    photo2: file(relativePath: { eq: "ledarny-branik/branik-2.jpg" }) { ...fluidImage }
    photo3: file(relativePath: { eq: "ledarny-branik/branik-3.jpg" }) { ...fluidImage }
    photo4: file(relativePath: { eq: "ledarny-branik/branik-4.jpg" }) { ...fluidImage }
    photo5: file(relativePath: { eq: "ledarny-branik/branik-5.jpg" }) { ...fluidImage }
    photo6: file(relativePath: { eq: "ledarny-branik/branik-6.jpg" }) { ...fluidImage }
    photo7: file(relativePath: { eq: "ledarny-branik/branik-7.jpg" }) { ...fluidImage }
    photo8: file(relativePath: { eq: "ledarny-branik/branik-8.jpg" }) { ...fluidImage }
    photo9: file(relativePath: { eq: "ledarny-branik/branik-9.jpg" }) { ...fluidImage }
    photo10: file(relativePath: { eq: "ledarny-branik/branik-10.jpg" }) { ...fluidImage }
    photo11: file(relativePath: { eq: "ledarny-branik/branik-11.jpg" }) { ...fluidImage }
  }
`
const LedarnyBranikPage = ({ data }) => (
  <Layout title="Ledárny Braník">
    <HorizontalImage marginTop borderBottom image={data.photo1.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo2.childImageSharp.fluid} />
    <VerticalText>
      <Heading>Revitalizace Ledáren Braník, Praha 4, 2015</Heading>
      <Text>
        Secesní areál Branických ledáren v Praze 4 byl vystavěn v letech 1909 - 1911. Areál obsahoval stáje pro 120 koní, budovu pro správce a halu pro skladování ledu. Budova ledáren sloužila své funkci do roku 1954. Od této doby objekty postupně chátrají. Ve své práci se snažím najít novou náplň pro tento areál a jeho blízké okolí. Již při první návštěvě místa bylo zřejmé, že se nebudu zabývat jen samotnými budovami ledáren ale i blízkým okolím. Pro toto rozsáhlé území a budovy ledáren bylo nutné mimo jiné vyřešit propustnost území pro cyklisty a inline bruslaře. Neužívaný stavební komplex chátrá poté, co byl v roce 1954 zbaven své původní funkce. Originální největší pražská „lednice“ pak sloužila jako skladiště brambor a postupně i pro jiné provizorní a nevhodné účely. V tento okamžik je řešené území pokryto veřejnosti nepropustnými areály. Tyto areály nejsou přiliš vhodně urbanistycky řešeny, proto je ruším a veškeré zázemí pro toto území přesunuji do ledáren. Ty se stávají rušným srdcem, místem shledávaní cyklistů, bruslařů, jachtařů, kempařů. Díky přesunutí všech provozů do areálu bylo možné zbytek krajiny řešit jako landscape. Tento krajinný ráz dodává ledárnám zpět jejich dominantnost v území.
      </Text>
    </VerticalText>
    <HorizontalImage marginTop borderBottom borderTop image={data.photo3.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo4.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo5.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo6.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo7.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo8.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo9.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo10.childImageSharp.fluid} />
    <VerticalText>
      <Text>
        Hlavní inspirací byla dobová fotografie zachycující podobu ledáren kolem roku 1920. Areál ledáren je zde zachycen bez náletové zeleně, která stavbu v současnosti obklopuje. Při návrhu jsem se snažil docílit toho, aby areál vytvořil opět takovouto dominantu. Interiér haly lednice je inspirovaný kostkami ledu, jejich povrchem a průhledností, vedením světla. Tyto poznatky jsem aplikoval na formu interiérových buněk, které dělí vnitřní prostor haly lednice a podporují nasvětlení interiéru. Věž rozhledny byla inspirována prasklinami v ledu, které se vytvoří vhozením ledu do vlažné vody. Na severu lokality vzniká na slepém rameni přírodní koupaliště, do něhož vtéká zatrubněný potok. Bývalá historická budova stájí slouží jako hotel s restaurací a zázemím pro kemping v jižním sektoru lokality, který byl přesunut od břehu řeky. Hlavní budova ledáren se stává srdcem sportovně - rekreačního areálu. Ve vložených nových segmentech najdeme restauraci, café, wellness, masáže, půjčovnu kol a inline bruslí se šatnami, dětský koutek i malou knihovnu. Vila správce slouží jako zázemí pro yacht club a pod úrovní terénu se nachází doky pro navazující přístav.  Návrh počítá s doplněním běžeckého okruhu, inline dráhy i s probíhající trasou cyklostezky.  Stávající historické objekty jsou zachovány v co největším rozsahu, nové hmoty se zřetelně vymezují vůči historickým částem.
      </Text>
    </VerticalText>
    <HorizontalImage marginTop borderTop image={data.photo11.childImageSharp.fluid} />
  </Layout>
)

export default LedarnyBranikPage
